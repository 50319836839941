<template>
    <b-modal
        v-model="isOpen"
        size="md"
        modal-class="vue-need-bootstrap"
        lazy
        centered
        :title="getTitle()"
        @close="closeModal"
        @cancel="closeModal"
        @ok="closeModal"
        @hide="closeModal"
    >
        <div class="py-1 overflow-auto" style="max-height: 340px">
            <div class="mb-3">
                <div class="hist-elem">{{ displayName }}</div>
                <div class="hist-id">{{ entityId }}</div>
            </div>
            <div v-if="auditEntries.length > 0" id="timeline">
                <div
                    v-for="(entry, index) in auditEntries"
                    :key="entry.entityId + entry.date"
                    class="pb-3"
                    :class="index == auditEntries.length - 1 ? 'timeline-item-last' : 'timeline-item-common'"
                >
                    <div class="mb-1 op-text">{{ parseOperation(entry.operation) }}</div>
                    <div class="mb-1 user-text">
                        {{ `${entry.user?.Name ?? ''} (${entry.user?.Username})` }}
                    </div>
                    <div class="mb date-text">{{ getFormattedDate(entry.date) }}</div>
                </div>
            </div>
        </div>
        <template #modal-footer="{ cancel }">
            <b-button class="ml-auto btn back-btn btn-secondary" size="sm" variant="secondary" @click="cancel">
                Close
            </b-button>
        </template>
    </b-modal>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { AuditEntity, AuditLogWithUser, AuditOperation } from '@extTypes/auditLog/types';
import { getAuditEntriesWithUsers } from '@/api/customily/auditLog';
import { DateFormat, formatDate } from '@/utils/dates';
import { useStore } from '@/store';

const props = defineProps<{
    value: boolean;
    entityType: AuditEntity;
    entityId?: string | number;
    entityCreatedDate?: Date | string;
    displayName?: string;
}>();

const loading = ref(true);
const auditEntries = ref<AuditLogWithUser[]>([]);
const { state } = useStore();

const emit = defineEmits<{
    (event: 'input', value: boolean): void;
    (event: 'close'): void;
}>();

const closeModal = () => {
    emit('close');
};

const isOpen = computed({
    get(): boolean {
        return props.value;
    },
    set(value: boolean) {
        emit('input', value);
    }
});

watch(
    () => props.entityId,
    () => {
        if (props.entityId) {
            getAuditEntriesWithUsers(props.entityType, `${props.entityId}`)
                .then(async (data) => {
                    if (!data.some((e) => e.operation == 'C') && props) {
                        data.unshift({
                            entity: props.entityType,
                            entityId: `${props.entityId}`,
                            operation: AuditOperation.CREATE,
                            performedByUserId: state.user?.customilyUser.Id!,
                            date: new Date(props.entityCreatedDate!),
                            impersonatedUserId: null,
                            action: 'null',
                            details: null,
                            user: {
                                Id: 0,
                                Name: state.user?.customilyUser.Name ?? '',
                                Username: state.user?.customilyUser.Username ?? ''
                            }
                        });
                    }

                    auditEntries.value = data.reverse();

                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                });
        }
    },
    { immediate: true }
);

const parseOperation = (operation: AuditOperation): string => {
    switch (operation) {
        case AuditOperation.CREATE:
            return 'Created';
        case AuditOperation.RESTORE:
            return 'Restored';
        case AuditOperation.UPDATE:
            return 'Updated';
        case AuditOperation.DELETE:
            return 'Deleted';
        default:
            return 'Unknown';
    }
};

const parseEntity = (entity: AuditEntity): string => {
    switch (entity) {
        case AuditEntity.LIBRARY:
            return 'Library';
        case AuditEntity.OPTION_SET:
            return 'Option Set';
        case AuditEntity.PRODUCT:
            return 'Product';
        case AuditEntity.TEMPLATE:
            return 'Template';
        default:
            return 'Unknown';
    }
};

const getTitle = (): string => {
    return `${parseEntity(props.entityType)} history`;
};

const getFormattedDate = (date: Date): string => {
    return formatDate(date, DateFormat.MMMddYYYYTime);
};
</script>

<style lang="scss" scoped>
#timeline .timeline-item-common {
    border-left: 1px solid #bfbfbf;
    position: relative;
    padding-inline-start: 20px;
    margin-left: 4px;
}

#timeline .timeline-item-last {
    position: relative;
    padding-inline-start: 20px;
    margin-left: 4px;
}

#timeline .timeline-item-common:after {
    position: absolute;
    display: block;
    top: 0;
    border-radius: 145px;
    border: 1px solid var(--Gray-scale-gray, #bfbfbf);
    background: var(--Gray-scale-white, #fefefe);
    left: -5px;
    height: 10px;
    width: 10px;
    content: '';
}

#timeline .timeline-item-last:after {
    position: absolute;
    display: block;
    top: 0;
    border-radius: 145px;
    border: 1px solid var(--Gray-scale-gray, #bfbfbf);
    background: var(--Gray-scale-white, #fefefe);
    left: -4px;
    height: 10px;
    width: 10px;
    content: '';
}

.hist-elem {
    color: var(--Gray-scale-gray-darkest, #333);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.hist-id {
    color: var(--Primary-primary, #7258e0);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.op-text {
    color: #8a8a8a;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.user-text {
    color: var(--Gray-scale-gray-darkest, #333);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.date-text {
    color: #565656;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
</style>
