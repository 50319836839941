export enum DateFormat {
    MMMddYYYY,
    MMMdd,
    MMMYYYY,
    MMMddYYYYTime
}

export const formatDate = (date: Date | string, format = DateFormat.MMMddYYYY) => {
    if (!date) return '';

    const value = date instanceof Date ? date : new Date(date);
    const options: Record<DateFormat, Intl.DateTimeFormatOptions> = {
        [DateFormat.MMMYYYY]: { month: 'short', year: 'numeric' },
        [DateFormat.MMMdd]: { month: 'short', day: 'numeric' },
        [DateFormat.MMMddYYYY]: { month: 'short', day: 'numeric', year: 'numeric' },
        [DateFormat.MMMddYYYYTime]: {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }
    };

    return new Intl.DateTimeFormat('en-US', options[format]).format(value);
};
