import api from '../baseAPIs/core';
import { CustomilyTemplate, CustomilyTemplateDto } from '@extTypes/api/customily/template';
import { LibraryCategory } from '@extTypes/api/customily/libraries';
import type {
    LinkedPodProductsResponse,
    ManualTemplateProductList,
    ProviderTemplateProductDTO,
    ResultListDTO
} from './typings';
import { TemplateProductVariants } from '@extTypes/providers/manualPod/product';
import { PodPlaceholders } from '@extTypes/pod/productInfo';

export const getProductTemplate = async (productId: string, useListEPS = false): Promise<CustomilyTemplate> => {
    const response = await api.get('Product', {
        params: {
            productId,
            useListEPS
        }
    });

    return response.data;
};

export const getTemplates = async (
    searchString: string,
    from: number,
    count: number
): Promise<CustomilyTemplateDto[]> => {
    const response = await api.get('Product/GetProductsFromTo', {
        params: {
            searchString: searchString,
            from: from,
            to: count
        }
    });

    return response.data;
};

export const getTemplatesCount = async (searchString: string): Promise<number> => {
    const response = await api.get('Product/GetProductsCount', {
        params: {
            searchString: searchString
        }
    });

    return response.data;
};

export const getProductLibraryName = async (id: number): Promise<string> => {
    const response = await api.get(`Libraries/${id}/Name`);

    return response.data;
};

export const getProductLibraryCategories = async (id: number): Promise<LibraryCategory[]> => {
    const response = await api.get(`Libraries/${id}/Categories`, {
        params: {
            searchString: '',
            from: 0,
            count: 0
        }
    });

    return response.data;
};

export const getLinkedPodProducts = async (podService: 'PRINTFUL' | 'PRINTIFY'): Promise<LinkedPodProductsResponse> => {
    const response = await api.get('TemplateProducts/LinkedPodProducts', {
        params: { podService }
    });
    return response.data;
};

export const getLinkedTemplateByProducts = async (
    podService: 'PRINTFUL' | 'PRINTIFY',
    productId: string
): Promise<{
    podData: { [templateId: string]: string[] } | { [printProviderId: string]: Map<string, string[]> };
    printProviderData: { printProvider: string; isMultiFile: boolean }[];
}> => {
    const response = await api.get('TemplateProducts/LinkedPodVariants', {
        params: { productId, podService }
    });

    return response.data;
};

export const getManualPodProducts = async (podId: number): Promise<ManualTemplateProductList> => {
    const response = await api.get('TemplateProducts/ManualPodProducts', {
        params: {
            podId
        }
    });
    return response.data;
};

export const getTemplateProductVariantsByProductId = (id: string): Promise<TemplateProductVariants> =>
    api.get(`TemplateProductVariants/${id}`).then(({ data }) => data);

export const listTemplateProducts = async (
    from: number,
    count: number,
    search?: string
): Promise<ResultListDTO<ProviderTemplateProductDTO>> =>
    api
        .get<ResultListDTO<ProviderTemplateProductDTO>>('/TemplateProducts/ListTemplateProducts', {
            params: { from, count, search }
        })
        .then(({ data }) => data);

export const getBestMatchTemplateProductIds = async (podPlaceholders: PodPlaceholders): Promise<string[]> => {
    const response = await api.post('Product/BestMatchTemplateProductIds', podPlaceholders);
    return response.data;
};
